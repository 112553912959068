.h_row {
  display: flex;
  flex-direction: row;
}

.h_row_sb {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.h_row_right {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
}

img {
  max-height: 200px;
  max-width: 200px;
  height: auto;
  width: auto;
}

/* .w_400 {
  width: 400px;
} */

/* 
.v_row {
  display: flex;
  flex-direction: column;
}
.padding2 {
  padding: 20px;
} */

/* text */
.green {
  color: green;
}
.red {
  color: red;
}
.blue {
  color: blue;
}
.white {
  color: white;
}
.bold {
  font-weight: bold;
}

/* specific */
.margin_pagination {
  margin: 0px 10px;
}
.btn_toLeft {
  width: 60px;
}

/* overridding bootstrap classes' settings */
.form-control {
  color: black !important;
}

.error {
  color: red;
}

.required {
  color: red;
}

.button_margin {
  margin: 0px 5px;
}
.form-text-area {
  resize: both;
  overflow: auto;
  min-height: 90px;
}
.easy-text,
label.easy-text {
  line-height: 1.4;
}

.color-warning {
  color: orange;
}

.sidebar-icon-only .sidebar .nav .nav-item .nav-link .badge {
  display: block;
}
table.table tr.highlight {
  background-color: yellow !important;
}
